import { Grommet, Grid, Image, Card, CardBody, CardHeader, CardFooter, Box, Anchor, Text } from 'grommet';
import styled from 'styled-components';

const StyledAnchor = styled(Anchor)`
  box-shadow: none;
  transition: box-shadow 0.2s;
  display: flex;
  justify-content: center;

  &:hover {
    box-shadow: 0px 8px 32px rgba(27, 28, 29, 0.1);
  }
`;

const AppBox = ({ title, link, image }) => {
  return (
    <StyledAnchor href={link} target="_blank" style={{ fontWeight: 400, textDecoration: 'none', color: '#1B1C1D' }}>
      <Box alignSelf="center" align="center" justify="center" width="xsmall" pad="small" hoverIndicator>
        <Image src={image} />
        <Text size="small" margin={{ top: 'xsmall' }}>
          {title}
        </Text>
      </Box>
    </StyledAnchor>
  );
};

const RenderedCard = () => {
  return (
    <Card>
      <CardHeader pad="small">
        <Box align="start" justify="center" flex="grow" fill="vertical" pad="small">
          <Text textAlign="center">M365 Apps</Text>
        </Box>
      </CardHeader>
      <CardBody pad={{ vertical: 'none', horizontal: 'small' }}>
        <Grid columns="xsmall" fill="horizontal" gap="small">
          <AppBox title="Outlook" link="https://www.office.com/" image="https://www.adenin.com/assets/images/wp-images/logo/outlook-inbox.svg"></AppBox>
          <AppBox title="OneDrive" link="https://www.office.com/" image="https://www.adenin.com/assets/images/wp-images/logo/onedrive.svg"></AppBox>
          <AppBox title="SharePoint" link="https://www.office.com/" image="https://www.adenin.com/assets/images/wp-images/logo/sharepoint-online.svg"></AppBox>
          <AppBox
            title="OneNote"
            link="https://www.office.com/"
            image="https://upload.wikimedia.org/wikipedia/commons/1/10/Microsoft_Office_OneNote_%282019%E2%80%93present%29.svg"
          ></AppBox>
          <AppBox
            title="PowerPoint"
            link="https://www.office.com/"
            image="https://upload.wikimedia.org/wikipedia/commons/0/0d/Microsoft_Office_PowerPoint_%282019%E2%80%93present%29.svg"
          ></AppBox>
          <AppBox
            title="Excel"
            link="https://www.office.com/"
            image="https://upload.wikimedia.org/wikipedia/commons/3/34/Microsoft_Office_Excel_%282019%E2%80%93present%29.svg"
          ></AppBox>
          <AppBox
            title="Delve"
            link="https://www.office.com/"
            image="https://upload.wikimedia.org/wikipedia/commons/b/bc/Microsoft_Office_Delve_%282020%E2%80%93present%29.svg"
          ></AppBox>
        </Grid>
      </CardBody>
      <CardFooter pad="small">
        <Anchor href={'https://www.office.com/'} target="_blank" style={{ fontWeight: 400, textDecoration: 'none' }}>
          <Box align="center" justify="start" direction="row" pad={{ vertical: 'xsmall', horizontal: 'small' }}>
            <Text color="#0076D5">All Apps</Text>
          </Box>
        </Anchor>
      </CardFooter>
    </Card>
  );
};

const M365LinksTest = () => {
  return (
    <Grommet>
      <Box margin="medium" width="400px">
        <RenderedCard></RenderedCard>
      </Box>
    </Grommet>
  );
};

export default M365LinksTest;
